import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";

import logo from "../../images/logoNavbar/LogoAvatar4.png";
import "./styleFooter.css";

const Footer = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div id="styleFooter">
      <div style={styleFooterBrand}>
        <div>
          <img src={logo} alt="logo" width={90} height={90} />
        </div>
        <sub>ERV</sub>
      </div>
      <div style={{textAlign:"center"}}>
        <h1>Koderick</h1>
        <div
          style={styleTerminos}
        >
          <p style={{marginLeft:"1em"}} onClick={handleShow}>Contactame </p>
          <Link to="/Terminos" style={{
            color: "inherit",
            textDecoration: "inherit",
            display: "contents",
          }}>
            <p style={{marginLeft:"1em"}}> Terminos y condiciones</p>
          </Link>
          <Link to="/Privacidad" style={{
            color: "inherit",
            textDecoration: "inherit",
            display: "contents",
            
          }}>
            <p style={{marginLeft:"1em"}}>Privacidad</p>
          </Link>
        </div>
      </div>
      <div style={styleFooterBrand}>
        <h4>Sígueme para más contenido</h4>
        <div style={styleFooterSocialLogos}>
          <a
            href="https://www.instagram.com/koderick9/"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h3 style={{ marginRight: ".5em" }} class="bi bi-instagram">
              {" "}
            </h3>
          </a>
          <a
            href="https://www.tiktok.com/@koderick9"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h3 style={{ marginRight: ".5em" }} class="bi bi-tiktok">
              {" "}
            </h3>
          </a>
          <a
            href="https://twitter.com/ErickRV19"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h3 style={{ marginRight: ".5em" }} class="bi bi-twitter">
              {" "}
            </h3>
          </a>
          <a
            href="https://paypal.me/erickrv19?country.x=MX&locale.x=es_XC"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h3 style={{ marginRight: ".5em" }} class="bi bi-paypal">
              {" "}
            </h3>
          </a>
          <a
            href="https://github.com/ErickRV19"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h3 style={{ marginRight: ".5em" }} class="bi bi-github">
              {" "}
            </h3>
          </a>

          <h3
            onClick={handleShow}
            style={{ marginRight: ".5em" }}
            class="bi bi-envelope-fill"
          >
            {" "}
          </h3>
        </div>
      </div>
      <Offcanvas show={show} onHide={handleClose} placement={"bottom"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Contactame</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Puedes contactarme en el siguiente correo</p>
          <p>
            <b>koderick.contacto@gmail.com</b>
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

const styleFooterBrand = {
  display: "flex",
  flexFlow: "column wrap",
  alignContent: "center",
  alignItems: "center",
};
const styleFooterSocialLogos = {
  display: "flex",
  flexFlow: "row wrap",
  alignContent: "center",
  alignItems: "center",
  margin: "1em 2.5em",
  color: "inherit",
  textDecoration: "inherit",
};
const styleTerminos = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center'
};

export default Footer;
