import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import data from "../../data/dataFeed.json";
import './StyleCardHomeVideo.css'

// let color1 = generate();

const CardHomeVideo = () => {
  return (
    <div style={styleHomeVideo}>
      
      {data &&
        data.length > 0 &&
        data
          .reverse()
          .slice(0, 4)
          .map((item) => (
            <Link
              style={{
                color: "inherit",
                textDecoration: "inherit",
                with: "10vw",
              }}
              to={`/Videos`}
            >
              <Card key={item.index} id="styleCardColor">
                <Card.Body id="styleCardBody">
                  <Card.Title
                    style={{ padding: ".5em 0em" }}
                  >
                    {item.title}
                  </Card.Title>
                  <div style={styleFooterCard}>
                  <h5
                      class="bi bi-youtube"
                      style={{ paddingRight: ".5em", color: "red" }}
                    > </h5>
                    <p>
                      <i
                        class="bi bi-calendar2-check-fill"
                        style={{ paddingRight: ".5em" }}
                      ></i>
                      {item.dateUpdate}
                    </p>

                  </div>
                </Card.Body>
              </Card>
            </Link>
          ))}
    </div>
  );
};
export default CardHomeVideo;



const styleHomeVideo = {
  display: "flex",
  flexDirection: "row-reverse",
  padding: "5vh 0vw",
  alignItems: "stretch",
  alignContent: "stretch",
  justifyContent: "space-between",
  flexWrap: "wrap",
  margin: "2em 0"
};

const styleFooterCard = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-evenly',
  alignItems: 'center'
};

// function createHex() {
//   var hexCode1 = "";
//   var hexValues1 = "0123456789abcdef";

//   for (var i = 0; i < 6; i++) {
//     hexCode1 += hexValues1.charAt(
//       Math.floor(Math.random() * hexValues1.length)
//     );
//   }
//   return hexCode1;
// }

// function generate() {
//   var deg = Math.floor(Math.random() * 360);

//   var gradient =
//     "linear-gradient(" +
//     deg +
//     "deg, " +
//     "#" +
//     createHex() +
//     ", " +
//     "#" +
//     createHex() +
//     ")";
//   return gradient;
// }
