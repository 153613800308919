import React from "react";
import data from "../../../data/dataComments.json";
import './StyleComent.css'

export const Comments = () => {
  return (
    <div >
       {data &&
        data.length > 0 &&
        data
          .map((item) => ( 
            <div id="styleComment">
            <h5>{item.name}</h5>
            <p>{item.Comment}</p>
            <p>{item.platform}</p>
            </div>

          ))}

     
    </div>
  );
};

