import React from "react";
import imagenSocial from "../../images/FondoVideoSocial.jpg";
import './StyleSocial.css'

const Social = () => {
  return (
    <div style={styleSocial}>
      <div style={styleBackImg}>
        <img
          src={imagenSocial}
          alt="logo"
          style={imagenSection}
          height={800}
          width={1500}
        />
      </div>
      <div id="styleFrontImg">
        <dir>

        <h2>Redes sociales</h2>
        <p class="lead"> <b>
          Sígueme en otras redes sociales en donde también compartimos mucho
          contenido.
        </b>
        </p>
        </dir>
        <div style={styleFooterSocialLogos}>
          <a
            href="https://www.instagram.com/koderick9/"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h1 style={{ marginRight: ".5em" }} class="bi bi-instagram">
              {" "}
            </h1>
          </a>
          <a
            href="https://www.tiktok.com/@koderick9"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h1 style={{ marginRight: ".5em" }} class="bi bi-tiktok">
              {" "}
            </h1>
          </a>
          <a
            href="https://twitter.com/ErickRV19"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h1 style={{ marginRight: ".5em" }} class="bi bi-twitter">
              {" "}
            </h1>
          </a>
          <a
            href="https://www.youtube.com/@Koderick"
            target="_blank"
            style={{
              color: "inherit",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            rel="noreferrer"
          >
            <h1 style={{ marginRight: ".5em" }} class="bi bi-youtube">
              {" "}
            </h1>
          </a>

        </div>
      </div>
    </div>
  );
};

export default Social;

const styleSocial = {
  display: "flex",
  flexFlow: "column nowrap",
  textAlign: "justify",
  alignItems: "baseline",
  margin: "5em 0em",
};

const styleBackImg = {
  width: "99vw",
  height: "60vh",
  left: "-50vw",
  zIndex: 1,
};

const imagenSection = {
  position: "relative",
  left: "-58px",
};



const styleFooterSocialLogos = {
    display: "flex",
    flexFlow: "row wrap",
    alignContent: "center",
    alignItems: "center",
    margin: "1em 2.5em",
    color: "inherit",
    textDecoration: "inherit",
  };