import React from "react";
import "./styles/styleRecursos.css";

function PrivacidadTerminos() {
  return (
    <div id="styleBodyrecursos">
      <div>

      <h1>Terminos y condiciones</h1>
      <br></br>
      <p>Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de Koderick, ubicado en koderick.dev. </p>

<p>Al acceder a este sitio web asumimos que acepta estos términos y condiciones. No continúe utilizando Koderick si no acepta aceptar todos los términos y condiciones indicados en esta página. </p>

<p>La siguiente terminología se aplica a estos Términos y Condiciones, Declaración de Privacidad y Aviso de Renuncia y todos los Acuerdos: "Cliente", "Usted" y "Su" se refiere a usted, la persona que inicia sesión en este sitio web y cumple con los términos y condiciones de la Compañía. "La Compañía", "Nosotros", "Nosotros", "Nuestro" y "Nosotros", se refiere a nuestra Compañía. "Parte", "Partes" o "Nosotros", se refiere tanto al Cliente como a nosotros mismos. Todos los términos se refieren a la oferta, aceptación y consideración del pago necesario para llevar a cabo el proceso de nuestra asistencia al Cliente de la manera más adecuada para el propósito expreso de satisfacer las necesidades del Cliente con respecto a la prestación de los servicios declarados de la Compañía, de conformidad con la legislación vigente de los Países Bajos y sujeta a ella. Cualquier uso de la terminología anterior u otras palabras en singular, plural, mayúscula y/o él/ella o ellos, se toman como intercambiables y por lo tanto como referencia a los mismos. </p>

<h3><strong>Cookies</strong></h3>

<p>Empleamos el uso de cookies. Al acceder a Koderick, usted aceptó usar cookies de acuerdo con la Política de privacidad de Koderick. </p>

<p>La mayoría de los sitios web interactivos utilizan cookies para que podamos recuperar los datos del usuario para cada visita. Las cookies son utilizadas por nuestro sitio web para permitir la funcionalidad de ciertas áreas para que sea más fácil para las personas que visitan nuestro sitio web. Algunos de nuestros socios afiliados/anunciantes también pueden utilizar cookies. </p>

<h3><strong>Licencia</strong></h3>

<p>A menos que se indique lo contrario, Koderick y/o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en Koderick. Todos los derechos de propiedad intelectual están reservados. Puede acceder a esto desde Koderick para su propio uso personal sujeto a las restricciones establecidas en estos términos y condiciones. </p>

<p>No debes:</p>
<ul>
    <li>Volver a publicar material de Koderick</li>
    <li>Vender, alquilar o sublicenciar material de Koderick</li>
    <li>Reproducir, duplicar o copiar material de Koderick</li>
    <li>Redistribuir contenido de Koderick</li>
</ul>

<p>Este Acuerdo comenzará en la fecha aquí indicada. Nuestros Términos y Condiciones fueron creados con la ayuda de <a href="https://h-supertools.com/web/terms-and-conditions-generator/">H-supertools Terms And Conditions Generator</a>. </p>

<p>Partes de este sitio web ofrecen una oportunidad para que los usuarios publiquen e intercambien opiniones e información en ciertas áreas del sitio web. Koderick no filtra, edita, publica ni revisa Comentarios antes de su presencia en el sitio web. Los comentarios no reflejan las opiniones de Koderick, sus agentes y/o afiliados. Los comentarios reflejan las opiniones de la persona que publica sus opiniones y opiniones. En la medida permitida por las leyes aplicables, Koderick no será responsable de los Comentarios ni de ninguna responsabilidad, daños o gastos causados y/o sufridos como resultado de cualquier uso y/o publicación y/o aparición de los Comentarios en este sitio web. </p>


<p>Koderick se reserva el derecho de monitorear todos los Comentarios y eliminar cualquier Comentario que pueda ser considerado inapropiado, ofensivo o cause incumplimiento de estos Términos y Condiciones.</p>

<p>Usted garantiza y declara que:</p>

<ul>
    <li>Usted tiene derecho a publicar los Comentarios en nuestro sitio web y tiene todas las licencias y consentimientos necesarios para hacerlo;</li>
    <li>Los comentarios no invaden ningún derecho de propiedad intelectual, incluyendo sin limitación los derechos de autor, patentes o marcas de terceros;</li>
    <li>Los Comentarios no contienen ningún material difamatorio, difamatorio, ofensivo, indecente o ilegal que sea una invasión de la privacidad</li>
    <li>Los Comentarios no se utilizarán para solicitar o promover negocios o actividades comerciales personalizadas o presentes o actividades ilegales. </li>
</ul>


<p>Usted otorga a Koderick una licencia no exclusiva para usar, reproducir, editar y autorizar a otros a usar, reproducir y editar cualquiera de sus Comentarios en cualquier y todos los formularios, formatos o medios. </p>

<h3><strong>Hipervinculación a nuestro contenido</strong></h3>

<p>Las siguientes organizaciones pueden enlazar a nuestro sitio web sin aprobación previa por escrito:</p>

<ul>
    <li>Agencias gubernamentales;</li>
    <li>Motores de búsqueda;</li>
    <li>Organizaciones de noticias;</li>
    <li>Los distribuidores de directorios en línea pueden enlazar a nuestro sitio web de la misma manera que hipervínculo a los sitios web de otras empresas incluidas en la lista; y</li>
    <li>Negocios acreditados en todo el sistema excepto solicitar organizaciones sin fines de lucro, centros comerciales de caridad y grupos de recaudación de fondos de caridad que no pueden hipervínculo a nuestro sitio Web. </li>
</ul>


<p>Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones u otra información del Sitio Web siempre y cuando el enlace: (a) no sea engañoso en modo alguno; (b) no implique falsamente el patrocinio, respaldo o aprobación de la parte de enlace y sus productos y/o servicios; y (c) encaja en el contexto del sitio de la parte que lo vincula. </p>

<p>Podemos considerar y aprobar otras solicitudes de enlaces de los siguientes tipos de organizaciones:</p>

<ul>
    <li>fuentes de información de consumidores y/o empresas conocidas;</li>
    <li>dot.com community sites;</li>
    <li>asociaciones u otros grupos que representan organizaciones benéficas;</li>
    <li>distribuidores de directorios en línea;</li>
    <li>portales de internet;</li>
    <li>empresas de contabilidad, derecho y consultoría; y</li>
    <li>instituciones educativas y asociaciones comerciales. </li>
</ul>


<p>Aprobaremos las solicitudes de enlace de estas organizaciones si decidimos que: (a) el enlace no nos haría ver desfavorablemente a nosotros mismos o a nuestros negocios acreditados; (b) la organización no tiene ningún registro negativo con nosotros; (c) el beneficio para nosotros de la visibilidad del hipervínculo compensa la ausencia de Koderick; y (d) el enlace está en el contexto de la información general del recurso. </p>

<p>Estas organizaciones pueden enlazar a nuestra página de inicio siempre y cuando el enlace: (a) no sea de ninguna manera engañoso; (b) no implique falsamente patrocinio, aprobación o aprobación de la parte que vincula y sus productos o servicios; y (c) se ajuste al contexto del sitio de la parte que vincula. </p>

<p>Si usted es una de las organizaciones enumeradas en el párrafo 2 anterior y está interesado en enlazar a nuestro sitio web, debe informarnos enviando un correo electrónico a Koderick. Por favor, incluya su nombre, el nombre de su organización, la información de contacto, así como la URL de su sitio, una lista de las direcciones URL desde las que tiene la intención de enlazar a nuestro sitio web, y una lista de las direcciones URL en nuestro sitio al que desea enlazar. Espere 2-3 semanas para una respuesta. </p>

<p>Las organizaciones aprobadas pueden enlazar a nuestro sitio web de la siguiente manera:</p>

<ul>
    <li>Por el uso de nuestro nombre corporativo; o</li>
    <li>Mediante el uso del localizador de recursos uniforme que está vinculado a; o</li>
    <li>Mediante el uso de cualquier otra descripción de nuestro sitio web que está vinculado a que tiene sentido dentro del contexto y el formato de contenido en el sitio de la parte de enlace. </li>
</ul>

<p>No se permitirá el uso del logotipo de Koderick u otras ilustraciones para vincular sin un acuerdo de licencia de marca registrada. </p>

<h3><strong>iFrames</strong></h3>

<p>Sin aprobación previa y permiso escrito, no puede crear marcos alrededor de nuestras páginas web que alteren de ninguna manera la presentación visual o la apariencia de nuestro sitio web.</p>

<h3><strong>Responsabilidad del contenido</strong></h3>

<p>No seremos responsables de ningún contenido que aparezca en su sitio web. Usted acepta protegernos y defendernos contra todas las reclamaciones que surjan en su sitio web. Ningún enlace(s) debe aparecer en cualquier Sitio Web que pueda ser interpretado como difamatorio, obsceno o criminal, o que infrinja, viole de otro modo, o promueva la infracción u otra violación de los derechos de terceros. </p>

<h3><strong>Tu privacidad</strong></h3>

<p>Por favor lea la Política de Privacidad</p>

<h3><strong>Reserva de derechos</strong></h3>

<p>Nos reservamos el derecho de solicitar que elimine todos los enlaces o cualquier enlace particular a nuestro sitio web. Usted aprueba la eliminación inmediata de todos los enlaces a nuestro Sitio web previa solicitud. También nos reservamos el derecho de modificar estos términos y condiciones y su política de enlaces en cualquier momento. Al enlazar continuamente a nuestro Sitio Web, usted acepta y sigue estos términos y condiciones. </p>

<h3><strong>Eliminación de enlaces de nuestro sitio web</strong></h3>

<p>Si encuentra algún enlace en nuestro sitio web que sea ofensivo por cualquier motivo, puede ponerse en contacto con nosotros e informarnos en cualquier momento. Consideraremos las solicitudes de eliminación de enlaces, pero no estamos obligados a responder directamente. </p>

<p>No nos aseguramos de que la información en este sitio web sea correcta, no garantizamos su integridad o exactitud; ni prometemos garantizar que el sitio web permanezca disponible o que el material en el sitio web se mantenga actualizado. </p>

<h3><strong>Descargo de responsabilidad</strong></h3>

<p>En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones relacionadas con nuestro sitio web y el uso de este sitio web. Nada en este descargo de responsabilidad será:</p>

<ul>
    <li>limitar o excluir nuestra o su responsabilidad por muerte o lesiones personales;</li>
    <li>limitar o excluir nuestra o su responsabilidad por fraude o falsedad fraudulenta;</li>
    <li>limitar cualquiera de nuestros o sus responsabilidades de cualquier manera que no esté permitida bajo la ley aplicable; o</li>
    <li>excluir cualquiera de nuestros o sus responsabilidades que no pueden ser excluidos bajo la ley aplicable. </li>
</ul>

<p>Las limitaciones y prohibiciones de responsabilidad establecidas en esta Sección y en otras partes en este descargo de responsabilidad: (a) están sujetas al párrafo anterior; y (b) gobiernan todas las responsabilidades que surjan bajo el descargo de responsabilidad, incluidas las responsabilidades que surjan en el contrato, en responsabilidad civil y por incumplimiento del deber legal. </p>

<p>Siempre y cuando el sitio web y la información y los servicios en el sitio web se proporcionan de forma gratuita, no seremos responsables de ninguna pérdida o daño de ninguna naturaleza. </p>

      </div>

      
    </div>
  );
}

export default PrivacidadTerminos;
