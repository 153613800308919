import React from "react";

import imagenVideos from "../images/pageRecursos/smenu.gif";
import recursos from "../images/pageRecursos/descargar.gif";
import video from "../images/pageRecursos/video.gif";
import archivo from "../images/pageRecursos/archivo.gif";

import "./styles/styleRecursos.css";


function VideosDetails() {
  
  return (
    <div id="styleBodyrecursos">
      <h1>Recursos</h1>
      <br></br>
      <h4>
        Para poder descargar los archivos necesarios debes de seguir los
        siguientes pasos.
      </h4>
      <ul id="instrucciones">
        <li>
          <h5>Ir a la página Videos</h5>
          <img
            src={imagenVideos}
            alt="Imagen del acceso a videos"
            width={600}
          />
        </li>
        <li>
          <h5>Buscar el video con el titulo de Youtube</h5>
        </li>
        <li>
          <h5>Entrar al video</h5>
          <img src={video} alt="Imagen del acceso a videos" width={400} />
        </li>
        <li>
          <h5>
            Entraras a la pagina en donde en la parte de abajo habra el boton
            para la descarga
          </h5>
          <img src={recursos} alt="Imagen del acceso a videos" />
        </li>
        <li>
          <h5>Descargar el archivo directo </h5>
          <img src={archivo} alt="Imagen del acceso a videos" />
        </li>
      </ul>
    </div>
  );
}

export default VideosDetails;
