import React from "react";
import CardAddsTerra1 from "../components/AddsTerra/CardAddsTerra1";
import  CardHomeVideo  from "../components/CardHomeVideo/CardHomeVideo";
import {Community}  from "../components/Community/Community";
import  Jumbotron  from "../components/Jumbotron/Jumbotron";
import Social from "../components/SocialMedia/Social";
function Home() {

  return (
    <div style={styleHome}>
      <Jumbotron />
      <h5>Ultimos videos publicados</h5>
        <CardHomeVideo />
        <sub>Haz clic para ver</sub>
        <br></br>
       <CardAddsTerra1 />
        <br></br>
        <Social />
        <Community />
    </div>
  );
}
export default Home;

const styleHome = {
  display: "flex",
  flexDirection:"column",
  padding: "1em 3em 1em 3em",
  alignItems: "flex-tart",
};



