import React from 'react'

const raw = `
<div>
<script type="text/javascript">
	atOptions = {
		'key' : '096700973991f78fd1d5a373f3b6d1e5',
		'format' : 'iframe',
		'height' : 90,
		'width' : 728,
		'params' : {}
	};
	document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.effectivecreativeformat.com/096700973991f78fd1d5a373f3b6d1e5/invoke.js"></scr' + 'ipt>');
</script>
</div>
`;
const CardAddsTerra1 = () => {
  return (
    <>
    <div dangerouslySetInnerHTML={{ __html: raw }}></div>
  </>
  )
}

export default CardAddsTerra1