import React from "react";
import { useParams } from "react-router-dom";
import data from "../data/dataFeed.json";

import './styles/styleVideoDetails.css'

function VideosDetails() {
  const { _id } = useParams();
  const thisVideo = data.find((item) => item._id === _id);

  return (
    <div id="styleBodyDetail">
      <h1>{thisVideo.title}</h1>
      <div id="container-iframe">
        <iframe id="responsive-iframe"
          src={thisVideo.video}
          title="Video Koderick"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>

      <p>{thisVideo.about}</p>
      <div style={styleFooterCard}>
        <p style={{ paddingRight: ".5em" }}>
          <i
            class="bi bi-calendar2-check-fill"
            style={{ color: "#1A387E", paddingRight: ".5em" }}
          ></i>
          {thisVideo.dateUpdate}
        </p>
        <p style={{ paddingRight: ".5em" }}>
          <i
            class="bi bi-tags-fill"
            style={{ color: "#1A387E", paddingRight: ".5em" }}
          ></i>
          {thisVideo.topic}
        </p>
      </div>

      <div style={styleFooterItem}>
        <h2>Patrocina un video</h2>
        <a
          href="https://www.buymeacoffee.com/koderick"
          target="_blank"
          style={{
            color: "inherit",
            textDecoration: "inherit",
            textAlign: "center",
          }} rel="noreferrer"
        >
          <p>
            Te agradezco el apoyo, por estar viendo los videos del canal y
            formar parte de esta increíble comunidad 🙏, ahora estaré creando un
            mejor contenido y más recurrente para el canal, me encanta compartir
            contenido y un poco de conocimiento con toda la comunidad.
          </p>
          <h5>
            🚨 ¡ Ahora hemos implementado el que puedas patrocinar videos del
            canal ! 🚨
          </h5>
          <p>
            Si está en tus posibilidades invitar una pizza, podrás apoyar al
            canal patrocinando un video a tu nombre te lo agradecería
            inmensamente.!! También puedes sugerir el tema/Software para un
            video y serás mencionado en el video, recuerda que esto es opcional
            y solo es una forma de apoyar al canal. 🎬
          </p>
          <p>
            (🧐 no olvides colocar tu nombre para el video que patrocinaste)
          </p>
          <h5>
            Gracias por ver el contenido, por suscribirte al canal, ya eres
            parte de la comunidad.👌
          </h5>
          <h4 class="bi bi-cash-coin" style={{ color: "orange" }}>
            {" "}
            Comparte una pizza{" "}
          </h4>
        </a>
        <a href="https://paypal.me/erickrv19?country.x=MX&locale.x=es_XC" rel="noreferrer" target="_blank" style={{ color: 'inherit', textDecoration: 'inherit'}} >
        <h4 class="bi bi-cash-coin" style={{ color: "#047cc1" }}>
            {" "}
            Comparte por paypal{" "}
          </h4>
        </a>
      </div>
      <div style={styleFooterItem}>
        <h2> 🚨 Recursos del video 🚨</h2>
        <p>
          Da clic en el siguiente icono y te llevara al link directo para poder
          descargar el archivo. 
          </p>
          <p>
          Asegurate que sea el video correcto.
        </p>
        <a
          href={thisVideo.link}
          target="_blank"
          style={{
            color: "inherit",
            textDecoration: "inherit",
            textAlign: "center",
          }} rel="noreferrer"
        >
          <h4 class="bi bi-arrow-down-circle-fill" style={{ color: "#b6f397" }}>
            {" "}
            Descargar
          </h4>
        </a>
      </div>
    </div>
  );
}

export default VideosDetails;

const styleFooterCard = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "space-evenly",
  alignItems: "center",
};


const styleFooterItem = {
  display: "flex",
  flexFlow: "column nowrap",
  textAlign: "justify",
  alignItems: "center",
  margin: '2em 0em'
};
