import React from "react";
import { Link } from "react-router-dom";


const Jumbotron = () => {
  return (
    <>
      <div class="jumbotron" style={{ margin: "3em 0em" }}>
        <h1 style={{ fontSize:'9vw' }}>Bienvenido</h1>
        <p class="lead">
          Gracias por visitar mi página, aquí encontrarás información extra,
          videos, comunicados, post, noticias, etc., así como
          los enlaces de descarga de algunos materiales de los videos que
          necesitas para seguir con el tutorial.{" "}
        </p>
        <hr class="my-4"></hr>
        <p>
          Todo el contenido descargable está dividido en cada video que se sube, directos, sin acortadores .
        </p>
        <p class="lead">
          <Link to="Recursos"class="btn btn-light btn-lg" role="button">
            Conocer mas
          </Link>
        </p>
      </div>
    </>
  );
};

export default Jumbotron;
