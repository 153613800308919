import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import { BrowserRouter } from "react-router-dom";
import Navbar from './components/Navbar/NavBar';
import Footer from './components/Footer/Footer';

ReactDOM.render(
  <BrowserRouter>
    <Navbar />
    <App />
    <Footer />
  </BrowserRouter>,
  document.getElementById("root")
);