
import AdsComponentVideo from "../components/AdSense/AdsComponentVideo";
import { CardFeed } from "../components/CardFeed/CardFeed";

function Videos() {
  return (
    <div style={styleFeed}>
      <h1> Videos </h1>
      <CardFeed />
      <br></br>
      <AdsComponentVideo dataAdSlot='3899560449' />
      <br></br>
    </div>
  );
}

const styleFeed = {
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  flexWrap: "wrap",
  padding: "5vh 0vw",
  alignItems: "center",
};

export default Videos;
