import React from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import data from "../../data/dataFeed.json";
import "./styleCardFedd.css";

export const CardFeed = () => {
  return (
    <div id="styleCards">
      {data &&
        data.length > 0 &&
        data.reverse().map( (item) => (
          <Link
            style={{ color: "inherit", textDecoration: "inherit" }}
            to={`/Videos/${item._id}`}
          >
            <Card style={styleCard} key={item.index}>
              <div>
                <iframe
                  width="400"
                  height="200"
                  src={item.video}
                  title="Video Koderick"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <Card.Body style={styleCardBody}>
                <Card.Title
                  style={{ padding: "1em 0em", textAlign: "justify" }}
                >
                  {item.title}
                </Card.Title>
                <Card.Text style={{ textAlign: "justify" }}>
                  {item.about}
                </Card.Text>
                <div style={styleFooterCard}>
                  <p>
                    <i
                      class="bi bi-calendar2-check-fill"
                      style={{ color: "red", paddingRight: ".5em" }}
                    ></i>
                    {item.dateUpdate}
                  </p>
                  <p>
                    <i
                      class="bi bi-tags-fill"
                      style={{ color: "red", paddingRight: ".5em" }}
                    ></i>
                    {item.topic}
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Link>
        ))}
    </div>
  );
};

const styleCardBody = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
};

const styleFooterCard = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  justifyContent: "space-evenly",
};

const styleCard = {
  background: "#2c2f31",
  width: "25rem",
  margin: "1em 1.5em",
  boxShadow: "2px 23px 34px 4px rgba(0,0,0,0.1)",
  borderWidth: 0,
};
