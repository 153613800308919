import React from "react";
import { Comments } from "./Comments/Comments";

import "./Community.css";

import logo from "../../images/logoNavbar/LogoAvatar12.png";

export const Community = () => {
  return (
    <div>
      <h2>Comunidad</h2>
      <p class="lead">
        Únete a la gran Comunidad de Koderick para estar informado de los
        últimos videos y noticias.
      </p>
      <div id="styleSectionCommunity">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a
            href="https://www.youtube.com/@Koderick?sub_confirmation=1"
            className="button beige"
            style={{
              color: "white",
              textDecoration: "inherit",
              textAlign: "center",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Suscribete
            <i
              style={{ marginLeft: ".5em", color: "red", fontSize: "2.5rem" }}
              class="bi bi-youtube"
            >
              {" "}
            </i>
          </a>
          <img src={logo} alt="logo" width={199} height={199} />
        </div>
        <div style={styleCommentsSection}>
          <h3>Comentarios</h3>
          <Comments />
        </div>
      </div>
    </div>
  );
};


const styleCommentsSection = {
  padding: "0em 3em",
  alignItems: "center",
  flexDirection: "column",
  display: "flex",
};
