import React, { useState } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import './styleNavbar.css'

import logo from "../../images/logoNavbar/LogoAvatar2.png";

function CollapsibleExample() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar collapseOnSelect expand="lg">
        <Container style={{display: 'flex', alignItems: 'flex-end'}}>
          <Navbar.Brand style={styleNavIcon}>
            <Link
              to="/"
              style={{
                color: "inherit",
                textDecoration: "inherit",
                display: "contents",
              }}
            >
              <img src={logo} alt="logo" width={60} height={60} />
              <h4>Koderick</h4>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
          >
            <Nav >
              <Nav.Link>
                <Link
                  to="/"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <h4 class="bi bi-house-fill"> Inicio</h4>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="Videos"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <h4 class="bi bi-youtube"> Videos</h4>
                </Link>
              </Nav.Link>
              <Nav.Link>
                <Link
                  to="Recursos"
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <h4 class="bi bi-arrow-down-circle-fill"> Recursos</h4>
                </Link>
              </Nav.Link>
            </Nav>
            <Nav id="navbarEnd">
              <Nav.Link id="navbarEndItem" onClick={handleShow}>
                <h4 class="bi bi-bell-fill">
                </h4>
                <h4 id="navbarEndItemName">Notificaciones</h4>
              </Nav.Link>
              <Nav.Link id="navbarEndItem" >
                <h4 class="bi bi-info-circle-fill"> </h4>
                <h4 id="navbarEndItemName">Información</h4>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Notificaciones</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <p>Por ahora no hay ninguna</p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default CollapsibleExample;

const styleNavIcon = {
  alignContent: "center",
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "flex-end",
};
