import React from "react";
import "./styles/styleRecursos.css";

function PrivacidadTerminos() {
  return (
    <div id="styleBodyrecursos">
      <div>

      <h1>Politica de privacidad</h1>
      <br></br>

      <p>En Koderick, accesible desde https://koderick.dev/, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de Política de privacidad contiene tipos de información que Koderick recopila y registra y cómo la utilizamos. </p>

      <p>Si tiene preguntas adicionales o necesita más información sobre nuestra Política de Privacidad, no dude en contactarnos. </p>

      <p>Esta Política de Privacidad se aplica solo a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web con respecto a la información que compartieron y/ o recopilan en Koderick. Esta política no es aplicable a ninguna información recopilada fuera de línea o a través de canales distintos de este sitio web. Nuestra Política de Privacidad fue creada con la ayuda de 
<a href="https://h-supertools.com/web/privacy-policy-generator/">H-supertools Generador de políticas de privacidad</a>. </p>

<h2>Consentimiento</h2>
<p>Al utilizar nuestro sitio web, usted acepta nuestra Política de Privacidad y acepta sus términos. </p>

<h2>Información que recopilamos</h2>

<p>La información personal que se le pide que proporcione, y las razones por las que se le pide que la proporcione, se le aclarará en el momento en que le pedimos que proporcione su información personal. </p>
<p>Si nos contacta directamente, podemos recibir información adicional sobre usted, como su nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y/ o archivos adjuntos que puede enviarnos, y cualquier otra información que pueda elegir proporcionar. </p>
<p>Cuando se registra para una Cuenta, podemos solicitar su información de contacto, incluidos elementos como nombre, nombre de la empresa, dirección, dirección de correo electrónico y número de teléfono. </p>

<h2>Cómo utilizamos su información</h2>

<p>Utilizamos la información que recopilamos de varias maneras, incluyendo:</p>

<ul>
<li>Proporcionar, operar y mantener nuestro sitio web</li>
<li>Mejorar, personalizar y ampliar nuestro sitio web</li>
<li>Comprender y analizar cómo utiliza nuestro sitio web</li>
<li>Desarrollar nuevos productos, servicios, características y funcionalidad</li>
<li>Comunicarse con usted, ya sea directamente o a través de uno de nuestros socios, incluso para el servicio al cliente, para proporcionarle actualizaciones y otra información relacionada con el sitio web, y para fines de marketing y promoción</li>
<li>Enviar correos electrónicos</li>
<li>Encontrar y prevenir el fraude</li>
</ul>

<h2>Archivos de registro</h2>

<p>Koderick sigue un procedimiento estándar de uso de archivos de registro. Estos archivos registran a los visitantes cuando visitan sitios web. Todas las empresas de hosting hacen esto y una parte de los análisis de servicios de hosting. La información recopilada por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), fecha y hora, páginas de referencia/salida, y posiblemente el número de clics. Estos no están vinculados a ninguna información que sea personalmente identificable. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica. </p>

<h2>Cookies y Web Beacons</h2>

<p>Al igual que cualquier otro sitio web, Koderick utiliza 'cookies'. Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes, y las páginas del sitio web a las que el visitante accedió o visitó. La información se utiliza para optimizar la experiencia de los usuarios personalizando el contenido de nuestra página web en función del tipo de navegador de los visitantes y/ u otra información. </p>

<h2>Cookies de Google DoubleClick DART</h2>

<p>Google es uno de un proveedor de terceros en nuestro sitio. También utiliza cookies, conocidas como cookies DART, para ofrecer anuncios a los visitantes de nuestro sitio en función de su visita a www.website.com y otros sitios en Internet. Sin embargo, los visitantes pueden optar por rechazar el uso de cookies de DART visitando el anuncio de Google y la Política de Privacidad de la red de contenidos en la siguiente URL - <a href="https://policies.google.google.com/technologies/ads"> https://policies.google.google.com/technologies/ads</a></p>

<h2>Nuestros socios de publicidad</h2>

<p>Algunos anunciantes en nuestro sitio pueden utilizar cookies y balizas web. Nuestros socios de publicidad se enumeran a continuación. Cada uno de nuestros socios publicitarios tiene su propia Política de Privacidad para sus políticas sobre datos de usuario. Para facilitar el acceso, a continuación, hiperenlazamos a sus Políticas de privacidad. </p>



<ul>
    <li>
        <p>Google</p>
        <p><a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></p>
    </li>
</ul>

<h2>Política de privacidad de los socios de publicidad</h2>

<p>Puede consultar esta lista para encontrar la Política de Privacidad de cada uno de los socios publicitarios de Koderick. </p>

<p>Los servidores publicitarios o redes publicitarias de terceros utilizan tecnologías como cookies, JavaScript o balizas web que se utilizan en sus respectivos anuncios y enlaces que aparecen en Koderick y que se envían directamente al navegador de los usuarios. Reciben automáticamente su dirección IP cuando esto ocurre. Estas tecnologías se utilizan para medir la eficacia de sus campañas publicitarias y/o para personalizar el contenido publicitario que ves en los sitios web que visitas. </p>

<p>Tenga en cuenta que Koderick no tiene acceso ni control sobre estas cookies que utilizan anunciantes de terceros. </p>

<h2>Políticas de privacidad de terceros</h2>

<p>La Política de Privacidad de Koderick no se aplica a otros anunciantes o sitios web. Por lo tanto, le aconsejamos que consulte las respectivas Políticas de privacidad de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo darse de baja de ciertas opciones. </p>

<p>Puede optar por desactivar las cookies a través de las opciones de su navegador individual. Para obtener información más detallada sobre la gestión de cookies con navegadores web específicos, se puede encontrar en los respectivos sitios web de los navegadores. </p>

<h2>Derechos de privacidad de CCPA (No vender mi información personal)</h2>

<p>Bajo la CCPA, entre otros derechos, los consumidores de California tienen derecho a:</p>
<p>Solicitar que una empresa que recopila datos personales de un consumidor revele las categorías y piezas específicas de datos personales que una empresa ha recopilado sobre los consumidores. </p>
<p>Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que una empresa haya recopilado. </p>
<p>Solicitar que una empresa que vende los datos personales de un consumidor, no venda los datos personales del consumidor. </p>
<p>Si hace una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros. </p>

<h2>Derechos de protección de datos del RGPD</h2>
<p>Nos gustaría asegurarnos de que conoce todos sus derechos de protección de datos. Cada usuario tiene derecho a lo siguiente:</p>
<p>Derecho de acceso - Tiene derecho a solicitar copias de sus datos personales. Podemos cobrarle una pequeña tarifa por este servicio. </p>
<p>El derecho a la rectificación - Usted tiene el derecho de solicitar que corrijamos cualquier información que considere inexacta. También tiene derecho a solicitar que completemos la información que considere incompleta. </p>
<p>El derecho a la supresión - Tiene derecho a solicitar que borremos sus datos personales, bajo ciertas condiciones. </p>
<p>El derecho a restringir el procesamiento - Tiene derecho a solicitar que restrinjamos el procesamiento de sus datos personales, bajo ciertas condiciones. </p>
<p>El derecho a oponerse al procesamiento - Usted tiene derecho a oponerse a nuestro procesamiento de sus datos personales, bajo ciertas condiciones. </p>
<p>El derecho a la portabilidad de datos - Tiene derecho a solicitar que transfiramos los datos que hemos recopilado a otra organización, o directamente a usted, bajo ciertas condiciones. </p>
<p>Si hace una solicitud, tenemos un mes para responderle. Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros. </p>

<h2>Información para niños</h2>

<p>Otra parte de nuestra prioridad es agregar protección para los niños mientras usan Internet. Animamos a los padres y tutores a observar, participar y/o monitorear y guiar su actividad en línea. </p>

<p>Koderick no recopila a sabiendas ninguna Información Personal Identificable de niños menores de 13 años. Si cree que su hijo proporcionó este tipo de información en nuestro sitio web, le recomendamos que se ponga en contacto con nosotros de inmediato y haremos todo lo posible para eliminar rápidamente dicha información de nuestros registros. </p>


      </div>

      
    </div>
  );
}

export default PrivacidadTerminos;
