import { Routes, Route } from "react-router-dom"
import Home from "./pages/Home"
import Videos from "./pages/Videos"
import VideosDetails from "./pages/VideosDetails"
import Recursos from "./pages/Recursos"
import 'bootstrap/dist/css/bootstrap.min.css';
import Privacidad from "./pages/Privacidad"
import Terminos from "./pages/Terminos"

function App() {
  return (
    <div style={styleApp}>
      <Routes>
        <Route path="/"  element={ <Home/> }/>
        <Route path="Videos" element={ <Videos/> } />
        <Route path="/Videos/:_id" element={ <VideosDetails/> } />
        <Route path="/Recursos" element={ <Recursos/> } />
        <Route path="/Privacidad" element={ <Privacidad/> } />
        <Route path="/Terminos" element={ <Terminos/> } />
      </Routes>
    </div>
  )
}

export default App

const styleApp ={
  backgroundColor: '#1f1f1f',
  color: 'white',
  //backgroundColor: 'rgb(246, 248, 255)',

}